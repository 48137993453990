jQuery(function($) {
  "use strict";

  // Defining a function to set size for #hero
  function fullscreen(){
    $('.hero-img').css({
      width: "100%",
      height: $(window).height() - 100
    });
  }

  fullscreen();

  // Run the function in case of window resize
  $(window).resize(function() {
    $('.hero-img').css({
      width: $(window).width()
    });
  });

  // morphtext
  $("#js-rotating").Morphext({
      // The [in] animation type. Refer to Animate.css for a list of available animations.
      animation: "fadeInUp",
      // An array of phrases to rotate are created based on this separator. Change it if you wish to separate the phrases differently (e.g. So Simple | Very Doge | Much Wow | Such Cool).
      separator: ",",
      // The delay between the changing of each phrase in milliseconds.
      speed: 2000,
      complete: function () {
          // Called after the entrance animation is executed.
      }
  });

  // activate viewportChecker
  $('.animateFadeInUp').addClass('invisible').viewportChecker({
    classToAdd: 'animated fadeInUp',
    classToRemove: 'invisible',
    offset: 100
  });

  $('.animateFadeInLeft').addClass('invisible').viewportChecker({
    classToAdd: 'animated fadeInLeft',
    classToRemove: 'invisible',
    offset: 100
  });

  $('.animateFadeInRight').addClass('invisible').viewportChecker({
    classToAdd: 'animated fadeInRight',
    classToRemove: 'invisible',
    offset: 100
  });

  $('.animateFadeIn').addClass('invisible').viewportChecker({
    classToAdd: 'animated fadeIn',
    classToRemove: 'invisible',
    offset: 100
  });

  // color for label when input element focused
  $('#form .form-control').on('focus', function() {
    $(this).prev('label').addClass('focus');
  });

  $('#form .form-control').on('blur', function() {
    $(this).prev('label').removeClass('focus');
  });

  // Cookie message link
  $('.cookie_message a').click(function(ev) {
    ev.preventDefault();
    $('a[data-target=#dataPolicy]').click();
  });

});
